import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import PageHeader from "../Common/PageHeader";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function All() {
  const navigate = useNavigate();
  const { isLoading, error, data } = useQuery("all", async () => {
    const { data } = await axios.get("/crew/dispatch");
    return data;
  });

  return (
    <>
      <PageHeader title="All Lists" />
      {isLoading && <p>Loading...</p>}
      {error && <p>Error :(</p>}
      <div className="d-grid">
        {!isLoading &&
          data &&
          data.map((crew) => (
            <React.Fragment key={crew.wo_crew}>
              <Button
                variant="secondary"
                onClick={() => navigate(`/lists/${crew.wo_crew}`)}
              >
                {" "}
                {crew.wo_crew}
              </Button>
              <hr />
            </React.Fragment>
          ))}
      </div>
    </>
  );
}
