import { useState } from 'react';
import { setRequestToken } from './axiosClient'

export default function useUser() {
  const getToken = () => {
    const tokenString = localStorage.getItem('user');
    const userToken = JSON.parse(tokenString);
    setRequestToken(userToken?.session.token);
    return userToken?.session.token
  };
  const getUser = () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    return user;
  }

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());


  const saveToken = user => {
    localStorage.setItem('user', JSON.stringify(user));
    setToken(user.session.token);
    setUser(user);
  };


  return {
    setToken: saveToken,
    token,
    user
  }
}