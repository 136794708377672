import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import "../../scss/pages/Login.scss";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

async function loginUser(credentials) {
  return await axios.post("/session", credentials);
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await loginUser({
        username,
        password,
      });
      console.log(userData);
      setToken(userData.data);
      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
      setError(error.response.data.error);
    }
  };

  return (
    <div className="login">
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="login-box row d-flex justify-content-center">
        <img src="/logo.png" />
        <form id="loginform" onSubmit={handleSubmit} className="d-grid">
          <div className="form-group pb-4">
            <label>Email address</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              aria-describedby="emailHelp"
              placeholder="Enter Username"
              onChange={(event) => setUserName(event.target.value)}
            />
          </div>
          <div className="form-group pb-4">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
