import axios from "axios";

export function setupApiClient() {
  //axios.defaults.baseURL = "http://localhost:3001";
  axios.defaults.baseURL = "https://cportal.newflowinnovations.com:3000/";


  //redirect to login if token is not present
  axios.interceptors.response.use(
    response => response,
    error => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.clear("token");
        window.location.href = `/login?r=${encodeURIComponent(window.location.pathname + window.location.search)}`;
      }
      return Promise.reject(error);
    }
  );
}

export function setRequestToken(token) {
  axios.defaults.headers.common["X-JWT-Token"] = token;
}



export default axios;