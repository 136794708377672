import React from "react";
import { BrowserRouter } from "react-router-dom";
import Login from "../Login/Login";
import useUser from "../../helpers/useUser";
import { Container } from "react-bootstrap";
import Router from "./Router";
import { Route, Routes } from "react-router-dom";
import Header from "./AppHeader";

function App() {
  const { token, setToken } = useUser();

  return (
    <div className="App">
      <>
        <BrowserRouter>
          {token ? (
            <>
              <Header />
              <Container>
                <Router />
              </Container>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login setToken={setToken} />} />
              <Route path="/login" element={<Login setToken={setToken} />} />
            </Routes>
          )}
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
