import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import PageHeader from "../Common/PageHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../helpers/axiosClient";

export default function Houses() {
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [totalRows, setTotalRows] = useState(0);
  const [pp, setPerPage] = useState(
    parseInt(searchParams.get("perPage"), 10) || 10
  );
  const [p, setPage] = useState(parseInt(searchParams.get("page"), 10) || 1);

  const houses = useQuery(["houses", pp, p], async () => {
    const { data } = await axios.get(`/house?limit=${pp}&page=${p}`);
    return data;
  });

  useEffect(() => {
    if (!houses.isLoading) {
      console.log(houses);
      setTotalRows(houses.data.total_items);
    }
  }, [houses.isLoading, houses?.data?.total_items]);

  useEffect(() => {
    window.history.replaceState(null, null, `/houses?page=${p}&perPage=${pp}`);
  }, [p, pp]);

  return (
    <div className="houses">
      <PageHeader title="Houses" />
      <DataTable
        keyField="cust_id"
        columns={[
          {
            name: "Address",
            selector: (row) => row.address,
            sortable: false,
            minWidth: "150px",
            compact: true,
          },
          {
            name: "Builder",
            selector: (row) => row.builder,
            sortable: false,
            grow: 0,
            minWidth: "50px",
            compact: true,
          },
          {
            name: "Subdivision",
            selector: (row) => row.subdivision,
            sortable: false,
          },
        ]}
        data={houses?.data?.data}
        progressPending={houses.isLoading}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={(rows) => setPerPage(rows)}
        onChangePage={(page) => {
          setPage(page);
        }}
        paginationDefaultPage={p}
        onRowClicked={(row) => {
          navigate(`/houses/${row.cust_id}`);
        }}
      ></DataTable>
    </div>
  );
}
