import AppNav from "./AppNav";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
export default function Header() {
  return (
    <Navbar
      bg="light"
      expand={"xl"}
      className="mb-3"
      collapseOnSelect
      sticky="top"
    >
      <Container>
        <Navbar.Brand href="/">
          <img src="/logo.png" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-sm`}
          aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-sm`}
            ></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <AppNav showColorMode={true} />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
