import { createContext } from "react";

export const themes = {
  dark: "dark_mode",
  light: "light_mode",
};

export const ThemeContext = createContext({
  theme: themes.dark,
  changeTheme: (theme) => {
    console.log(theme);
    this.theme = theme;
  },
});