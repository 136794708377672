import { ThemeContext, themes } from "../../theme/themeContext";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Navbar, Nav } from "react-bootstrap";
import useUser from "../../helpers/useUser";
import { useNavigate } from "react-router-dom";

export default function AppNav(props) {
  const { token, setToken } = useUser();
  const navigate = useNavigate();

  const { variant, showColorMode = false, extraClasses = "" } = props;

  const logout = () => {
    setToken(null);
    navigate("/login", { replace: true });
  };

  return (
    <Nav
      className={`justify-content-end flex-grow-1 ${extraClasses}`}
      variant={variant}
    >
      <LinkContainer to="/lists/mine">
        <Nav.Link>My List</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/lists/all">
        <Nav.Link>All Lists</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/readiness">
        <Nav.Link>Readiness Walks</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/houses">
        <Nav.Link>View Houses</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/login" onClick={logout}>
        <Nav.Link>Logout</Nav.Link>
      </LinkContainer>
      {showColorMode && (
        <Navbar.Text className="ps-2">
          <ThemeContext.Consumer>
            {({ theme, changeTheme }) => (
              <>
                {console.log("in consumer", theme)}
                <Form.Check
                  type="switch"
                  checked={theme == themes.dark}
                  id="theme-switch"
                  onChange={() => {
                    changeTheme(
                      theme == themes.dark ? themes.light : themes.dark
                    );
                  }}
                  label={theme == themes.dark ? "Light Mode" : "Dark Mode"}
                />
              </>
            )}
          </ThemeContext.Consumer>
        </Navbar.Text>
      )}
    </Nav>
  );
}
