import axios from "axios";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import PageHeader from "../Common/PageHeader";

export default function List(props) {
  const { crew } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [totalRows, setTotalRows] = useState(0);
  const [pp, setPerPage] = useState(searchParams.get("perPage") || 10);
  const [p, setPage] = useState(searchParams.get("page") || 1);

  const title = crew == "mine" ? "My List" : `${crew}'s List`;

  const dispatch = useQuery(["dispatch", crew], async () => {
    const { data } = await axios.get(
      `/dispatch/uncompleted/crew/${crew}?limit=${pp}&page=${p}`
    );
    return data.data;
  });

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/lists/${crew}?page=${p}&perPage=${pp}`
    );
  }, [p, pp]);

  return (
    <>
      <PageHeader title={title} />

      <DataTable
        keyField="wo_id"
        columns={[
          {
            name: "WO Date",
            selector: (row) => row.s_date,
            sortable: false,
            compact: true,
          },
          {
            name: "ASSND Date",
            selector: (row) => row.assnd_date,
            sortable: false,
            compact: true,
          },
          {
            name: "Address",
            selector: (row) => row.address,
            sortable: false,
            compact: true,
          },
          {
            name: "Desc",
            selector: (row) => row.wo_desc,
            sortable: false,
            compact: true,
          },
        ]}
        data={dispatch?.data}
        progressPending={dispatch?.isLoading}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={(rows) => setPerPage(rows)}
        onChangePage={(page) => {
          setPage(page);
        }}
        paginationDefaultPage={p}
        onRowClicked={(row) => {
          navigate(`/workorder/${row.wo_id}`);
        }}
      ></DataTable>
    </>
  );
}
