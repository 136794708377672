import React from "react";
import { useEffect, useState } from "react";
import { ButtonGroup, Table, Button, Row, Col, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import axios from "../../helpers/axiosClient";
import useUser from "../../helpers/useUser";
import Loading from "../Common/Loading";
import PageHeader from "../Common/PageHeader";

import "../../scss/pages/WorkOrder.scss";

export default function ReadinessWO({ ready }) {
  const { id } = useParams();

  const workOrder = useQuery(["readywalk", id], async () => {
    const { data } = await axios.get(`/readywalk/workorder/${id}`);
    return data;
  });

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [update, setUpdate] = useState({});
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      if (!workOrder.isLoading) {
        const qs = await axios.get(
          `/readywalk/questions/${workOrder.data.wo_desc}?limit=100`
        );
        setQuestions(qs.data.data);
      }
    })();
  }, [workOrder.isLoading]);

  useEffect(() => {
    let answers = {};
    questions.map((x) => (answers[x.question] = null));
    setAnswers(answers);
  }, [questions.length]);

  const updateAnswers = (question, answer) => {
    answers[question] = answer;
    setAnswers({ ...answers });
  };

  return (
    <div className="work-order">
      <PageHeader
        title={`${ready ? "Walk Ticket" : "Work Order"} ${
          !workOrder.isLoading && workOrder.data.wo_id
        }`}
      />
      {workOrder.isLoading ? (
        <Loading />
      ) : (
        <>
          <Table>
            <tbody>
              <tr>
                <th>WO ID</th>
                <td>{workOrder.data.wo_id}</td>
              </tr>
              <tr>
                <th>Stage</th>
                <td>{workOrder.data.wo_desc}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{workOrder.data.address}</td>
              </tr>
              <tr>
                <th>Lot/Block</th>
                <td>{workOrder.data.lot_block}</td>
              </tr>
              <tr>
                <th>Builder</th>
                <td>{workOrder.data.jobBuilder}</td>
              </tr>
              <tr>
                <th>Subdivision</th>
                <td>{workOrder.data.subdiv}</td>
              </tr>
              <tr>
                <th>Series, Plan, &amp; Base</th>
                <td>{workOrder.data.series_plan_template}</td>
              </tr>
              <tr>
                <th>Schedule Date</th>
                <td>{workOrder.data.serv_date}</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <div className="text-center">
            <h2>Walk Ticket Questions</h2>

            {questions.length == 0 ? (
              <Loading />
            ) : (
              questions.map((question) => (
                <div className="pb-4 text-center" key={question.question}>
                  <h4>{question.question}</h4>
                  <Row>
                    <Col className="d-grid">
                      <ButtonGroup>
                        <Button
                          onClick={() =>
                            updateAnswers(question.question, "Yes")
                          }
                          variant={
                            answers[question.question] === "Yes"
                              ? "success"
                              : "secondary"
                          }
                        >
                          Yes
                        </Button>
                        {answers[question.question] == "Yes" && (
                          <Button
                            onClick={() =>
                              updateAnswers(question.question, null)
                            }
                            className="btn-cancel-answer"
                            variant="success"
                            active
                          >
                            &times;
                          </Button>
                        )}
                      </ButtonGroup>
                    </Col>
                    <Col className="d-grid">
                      <ButtonGroup>
                        <Button
                          onClick={() => updateAnswers(question.question, "No")}
                          variant={
                            answers[question.question] === "No"
                              ? "danger"
                              : "secondary"
                          }
                        >
                          No
                        </Button>
                        {answers[question.question] == "No" && (
                          <Button
                            onClick={() =>
                              updateAnswers(question.question, null)
                            }
                            className="btn-cancel-answer"
                            variant="danger"
                            active
                          >
                            &times;
                          </Button>
                        )}
                      </ButtonGroup>
                    </Col>
                  </Row>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
}
