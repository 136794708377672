import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './themeContext';

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(null);

  function changeTheme(theme) {
    console.log(theme);
    setTheme(theme);
  }

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    console.log("localstorage", theme);
    if (theme && theme !== "null") {
      setTheme(theme);
    } else {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme(themes.dark);
      } else {
        setTheme(themes.light);
      }
    }
  }, []);

  useEffect(() => {
    console.log("set local storage", theme);
    localStorage.setItem('theme', theme);
    switch (theme) {
      case themes.light:
        document.body.classList.add(themes.light);
        document.body.classList.remove(themes.dark);
        break;
      case themes.dark:
      default:
        document.body.classList.remove(themes.light);
        document.body.classList.add(themes.dark);
        break;
    }
  }, [theme]);


  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {console.log("in provider", theme)}
      {props.children}
    </ThemeContext.Provider>
  );
}