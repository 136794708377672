import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PageHeader(props) {
  const { showBackButton = true, title, subtitle, showAddButton } = props;

  const navigate = useNavigate();
  return (
    <div className="page-header">
      <Row>
        <h1 className="col-10">{title}</h1>
        {showBackButton && (
          <Col xs={2}>
            <Button
              className="float-end"
              variant="primary"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
}
