import React from "react";
import { useEffect, useState } from "react";
import {
  ButtonGroup,
  Table,
  Button,
  Row,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import axios from "../../helpers/axiosClient";
import useUser from "../../helpers/useUser";
import Loading from "../Common/Loading";
import PageHeader from "../Common/PageHeader";

import "../../scss/pages/WorkOrder.scss";

//convert mm/dd/yyyy to Date
function convertDateToISO(date) {
  var dateParts = date.split("/");
  return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
}
function convertDateFromISO(date) {
  var dateParts = date.split("-");
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
}

export default function WorkOrder() {
  const { id } = useParams();

  const workOrder = useQuery(["workorder", id], async () => {
    const { data } = await axios.get(`/workorder/id/${id}`);
    return data;
  });

  const [crew, setCrew] = useState([]);
  const [update, setUpdate] = useState({});
  const [serviceDate, setServiceDate] = useState("");
  const { user } = useUser();
  const [crewId, setCrewId] = useState(-1);
  const [show, setShow] = useState(false);
  const [pay, setPay] = useState({});

  useEffect(() => {
    (async () => {
      if (!workOrder.isLoading) {
        const crew = await axios.get("/crew?limit=100");
        setCrew(crew.data.data);
        const selectedCrew = crew.data.data.find(
          (x) => x.crew == workOrder.data.crew
        );
        setCrewId(selectedCrew?.crID);
        setUpdate({ ...update, completed: workOrder.data.completed });
        setServiceDate(convertDateToISO(workOrder.data.serv_date));
      }
    })();
  }, [workOrder.isLoading]);

  return (
    <div className="work-order">
      <PageHeader
        title={`WO ${!workOrder.isLoading && workOrder.data.wo_id}`}
      />
      {workOrder.isLoading ? (
        <Loading />
      ) : (
        <>
          <Button
            variant="primary"
            className="mr-2"
            onClick={() => setShow(true)}
          >
            Pay Extra
          </Button>

          <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Extra Payroll</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>Address</th>
                    <td>{workOrder.data.address}</td>
                  </tr>
                  <tr>
                    <th>Stage</th>
                    <td>
                      <Form.Control
                        onChange={(e) =>
                          setPay({ ...pay, stage: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Crew</th>
                    <td>
                      <Form.Select
                        value={crewId}
                        onChange={(e) =>
                          setPay({ ...pay, crew: e.target.value })
                        }
                      >
                        <option value="">Select</option>
                        {crew.map((x) => (
                          <option key={x.crID} value={x.crID}>
                            {x.crew}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>
                      <Form.Control
                        type="number"
                        onChange={(e) =>
                          setPay({ ...pay, amount: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Comments</th>
                    <td>
                      <Form.Control
                        as="textarea"
                        onChange={(e) =>
                          setPay({ ...pay, comments: e.target.value })
                        }
                      ></Form.Control>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  alert(JSON.stringify(pay, null, 2));
                }}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Table>
            <tbody>
              <tr>
                <th>Schedule Date</th>
                <td>
                  <Form.Control
                    type="date"
                    value={serviceDate}
                    onChange={(e) => {}}
                    readOnly={user.profile.user_group_id != 1}
                  />
                </td>
              </tr>
              <tr>
                <th>Assnd Date</th>
                <td>{workOrder.data.assnd_date}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{workOrder.data.address}</td>
              </tr>
              <tr>
                <th>WO Type</th>
                <td>{workOrder.data.wo_type}</td>
              </tr>
              <tr>
                <th>WO Desc</th>
                <td>{workOrder.data.wo_desc}</td>
              </tr>
              <tr>
                <th>Called in By</th>
                <td>{workOrder.data.called_in_by}</td>
              </tr>
              <tr>
                <th>Comments</th>
                <td>
                  <div className="comments-shadow"></div>
                  <div className="comments">{workOrder.data.wo_comms}</div>
                  <div className="comments-shadow"></div>
                </td>
              </tr>
            </tbody>
          </Table>
          <hr />

          <Form.Group controlId="response" className="pb-4">
            <Form.Label>Response</Form.Label>
            <Form.Control
              as="textarea"
              onChange={(e) =>
                setUpdate({ ...update, response: e.target.value })
              }
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="assignee" className="pb-4">
            <Form.Label>Assignee</Form.Label>
            <Form.Select
              disabled={user.profile.user_group_id != 1}
              value={crewId}
              onChange={(e) => {
                setUpdate({ ...update, crew_id: +e.target.value });
              }}
            >
              <option value="-1">Select</option>
              {crew.map((x) => (
                <option key={x.crID} value={x.crID}>
                  {x.crew}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {workOrder.data.waterMeter == 0 && (
            <Form.Group className="pb-4">
              <label>Is there a water meter?</label>
              <Row>
                <Col className="d-grid">
                  <ButtonGroup>
                    <Button
                      onClick={() => setUpdate({ ...update, waterMeter: 1 })}
                      variant={
                        update.waterMeter === 1 ? "success" : "secondary"
                      }
                    >
                      Yes
                    </Button>
                    {update.waterMeter == 1 && (
                      <Button
                        onClick={() =>
                          setUpdate({ ...update, waterMeter: undefined })
                        }
                        className="btn-cancel-answer"
                        variant="success"
                        active
                      >
                        &times;
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
                <Col className="d-grid">
                  <ButtonGroup>
                    <Button
                      onClick={() => setUpdate({ ...update, waterMeter: 0 })}
                      variant={update.waterMeter == 0 ? "danger" : "secondary"}
                    >
                      No
                    </Button>
                    {update.waterMeter == 0 && (
                      <Button
                        onClick={() =>
                          setUpdate({ ...update, waterMeter: undefined })
                        }
                        className="btn-cancel-answer"
                        variant="danger"
                        active
                      >
                        &times;
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
            </Form.Group>
          )}
          <Form.Group className="pb-4">
            <label>Completed</label>
            <Row>
              <Col className="d-grid">
                <ButtonGroup>
                  <Button
                    onClick={() => setUpdate({ ...update, completed: 1 })}
                    variant={update.completed === 1 ? "success" : "secondary"}
                  >
                    Yes
                  </Button>
                  {update.completed == 1 && (
                    <Button
                      onClick={() =>
                        setUpdate({ ...update, completed: undefined })
                      }
                      className="btn-cancel-answer"
                      variant="success"
                      active
                    >
                      &times;
                    </Button>
                  )}
                </ButtonGroup>
              </Col>
              <Col className="d-grid">
                <ButtonGroup>
                  <Button
                    onClick={() => setUpdate({ ...update, completed: 0 })}
                    variant={update.completed == 0 ? "danger" : "secondary"}
                  >
                    No
                  </Button>
                  {update.completed == 0 && (
                    <Button
                      onClick={() =>
                        setUpdate({ ...update, completed: undefined })
                      }
                      className="btn-cancel-answer"
                      variant="danger"
                      active
                    >
                      &times;
                    </Button>
                  )}
                </ButtonGroup>
              </Col>
            </Row>
          </Form.Group>
        </>
      )}
    </div>
  );
}
