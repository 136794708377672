import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../helpers/axiosClient";
import PageHeader from "../Common/PageHeader";

export default function Readiness() {
  const { subdivision = "all", stage = "all" } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [sub, setSub] = useState(subdivision);
  const [sta, setSta] = useState(stage);
  const [totalRows, setTotalRows] = useState(0);
  const [pp, setPerPage] = useState(searchParams.get("perPage") || 10);
  const [p, setPage] = useState(searchParams.get("page") || 1);

  const subdivisions = useQuery("subdivisons", async () => {
    return await axios.get("/subdivision");
  });

  const readiness = useQuery([`readiness`, sub, sta, pp, p], async () => {
    return await axios.get(
      `/readywalk/subdivision/${sub.toLowerCase()}/stage/${sta.toLowerCase()}?limit=${pp}&page=${p}`
    );
  });

  useEffect(() => {
    if (!readiness.isLoading) {
      //console.log(readiness.data);
      setTotalRows(readiness.data.data.total_items);
    }
  }, [readiness.isLoading, readiness?.data?.data?.total_items]);

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/readiness/${sub}/${sta}?page=${p}&perPage=${pp}`
    );
  }, [sub, sta, p, pp]);

  return (
    <>
      <PageHeader title="Readiness Walks"></PageHeader>
      {/* <pre>{JSON.stringify(subdivisions, null, 2)}</pre> */}
      <Row>
        <Col>
          <Form.Select
            value={sub}
            onChange={(e) => {
              setSub(e.target.value);
            }}
          >
            <option value="all">All</option>
            {subdivisions?.data?.data?.map(({ subdivision }) => (
              <option
                key={subdivision.toLowerCase()}
                value={subdivision.toLowerCase()}
              >
                {subdivision}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            value={sta}
            onChange={(e) => {
              setSta(e.target.value);
            }}
          >
            <option value="all">All Stages</option>
            <option value="GROUND">Ground Readiness</option>
            <option value="LOOP">Loop Readiness</option>
            <option value="TOPOUT">Topout Readiness</option>
            <option value="TRIM">Trim Readiness</option>
          </Form.Select>
        </Col>
      </Row>
      <DataTable
        keyField="wo_id"
        columns={[
          {
            name: "Address",
            selector: (row) => row.address,
            sortable: false,
            minWidth: "150px",
            compact: true,
          },
          {
            name: "L/B",
            selector: (row) => row.lot_block,
            sortable: false,
            grow: 0,
            minWidth: "50px",
            compact: true,
          },
          {
            name: "Subdivision",
            selector: (row) => row.subdivision,
            sortable: false,
          },
          {
            name: "Stage",
            selector: (row) => row.wo_desc,
            sortable: false,
            minWidth: "75px",
            grow: 0,
            compact: true,
          },
        ]}
        data={readiness?.data?.data?.data}
        progressPending={readiness?.isLoading}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={(rows) => setPerPage(rows)}
        onChangePage={(page) => {
          setPage(page);
        }}
        paginationDefaultPage={p}
        onRowClicked={(row) => {
          navigate(`/readiness/${row.wo_id}`);
        }}
      ></DataTable>
    </>
  );
}
