import React from 'react';
import All from '../Lists/All';
import House from '../Houses/House';
import Houses from '../Houses/Houses';
import Readiness from '../Readiness/Readiness';
import WorkOrder from '../Workorder/WorkOrder'
import { Route, Routes, useLocation } from 'react-router-dom';
import List from '../Lists/List';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Home from './Home';
import ReadinessWO from '../Readiness/ReadinessWO';

export default function Router() {
  const location = useLocation();
  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lists/all" element={<All />} />
          <Route path="/lists/:crew" element={<List />} />
          <Route path="/readiness" element={<Readiness />} />
          <Route path="/readiness/:subdivision/:stage" element={<Readiness />} />
          <Route path="/houses" element={<Houses />} />
          <Route path="/houses/:id" element={<House />} />
          <Route path="/readiness/:id" element={<ReadinessWO />} />
          <Route path="/workorder/:id" element={<WorkOrder ready={false} />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>)
}