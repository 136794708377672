import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../Common/PageHeader";
import axios from "../../helpers/axiosClient";
import { Table, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

export default function House() {
  const { id } = useParams();

  const [update, setUpdate] = useState({});

  const house = useQuery(["house", id], async () => {
    const { data } = await axios.get(`/house/${id}`);
    return data;
  });

  useEffect(() => {
    if (!house.isLoading) {
      setUpdate(house.data);
    }
  }, [house.isLoading, house?.data]);

  const navigate = useNavigate();

  const [totalRows, setTotalRows] = useState(0);
  const [pp, setPerPage] = useState(10);
  const [p, setPage] = useState(1);

  const wos = useQuery(["workorders", id], async () => {
    const { data } = await axios.get(
      `/workorder/customer/${id}?limit=${pp}&page=${p}`
    );
    return data;
  });

  useEffect(() => {
    if (!wos.isLoading) {
      setTotalRows(wos.data.total_items);
    }
  }, [wos.isLoading, wos?.data]);

  return (
    <div className="house">
      <PageHeader title={`Customer Info`} />
      <Table>
        <tbody>
          <tr>
            <td>Address</td>
            <td>{update.address}</td>
          </tr>
          <tr>
            <td>Builder</td>
            <td>{update.builder}</td>
          </tr>
          <tr>
            <td>Subdivision</td>
            <td>{update.subdivision}</td>
          </tr>
          <tr>
            <td>Series</td>
            <td>{update.series}</td>
          </tr>
          <tr>
            <td>Plan</td>
            <td>{update.plan}</td>
          </tr>
          <tr>
            <td>Base Template</td>
            <td>{update.base_template}</td>
          </tr>
          <tr>
            <td>Home Owner</td>
            <td>
              <Form.Check
                type="switch"
                checked={update.home_owner == 1}
                onChange={() =>
                  setUpdate({
                    ...update,
                    home_owner: update.home_owner == 1 ? 0 : 1,
                  })
                }
              ></Form.Check>
            </td>
          </tr>
        </tbody>
      </Table>

      <DataTable
        keyField="wo_id"
        columns={[
          {
            name: "WO Date",
            selector: (row) => row.s_date,
            sortable: false,
            compact: true,
          },
          {
            name: "Desc",
            selector: (row) => row.wo_desc,
            sortable: false,
            compact: true,
          },
          {
            name: "Crew",
            selector: (row) => row.crew,
            sortable: false,
            compact: true,
          },
          {
            name: "Comments",
            selector: (row) => row.comments,
            sortable: false,
            compact: true,
          },
          {
            name: "Completed",
            selector: (row) => row.completed,
            sortable: false,
            compact: true,
          },
        ]}
        data={wos?.data?.data}
        progressPending={wos?.isLoading}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={(rows) => setPerPage(rows)}
        onChangePage={(page) => {
          setPage(page);
        }}
        paginationDefaultPage={p}
        onRowClicked={(row) => {
          navigate(`/workorder/${row.wo_id}`);
        }}
      ></DataTable>
    </div>
  );
}
