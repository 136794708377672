import React from "react";
import PageHeader from "../Common/PageHeader";
import AppNav from "./AppNav";
import useUser from "../../helpers/useUser";

export default function Home() {
  const { user } = useUser();
  return (
    <>
      <PageHeader
        title={`Hello ${user.profile.user_name}`}
        showBackButton={false}
      />
      <AppNav variant="pills" extraClasses="flex-column" />
    </>
  );
}
